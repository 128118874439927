<template>
  <div class="mt-4 pt-2">
    <v-card :loading="dataWait">
      <PlotlyView
        :title="title"
        :data="combinedPlotData"
        :plotly-config="plotlyConfig"
        :full-screen-supported="fullScreenSupported"
        :autoscale="autoscale"
      />
    </v-card>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { DateTime } from '#mixins/dateTime'

  import { EMPTY_GRAPH, combinePlotData, getAxisData } from '#utils/generic-graph/generic-graph'

  import { GenericGraphStore, PrefsStore } from '#stores'

  @Component
  class GenericGraph extends mixins(DateTime) {
    @Prop() public title!: any
    @Prop({ default: 600 }) public graphHeight!: number
    @Prop() private userUuid!: any
    @Prop() private ring!: any
    @Prop() private graphs!: string[]
    @Prop() private startDate!: string
    @Prop() private endDate!: string
    @Prop({ default: true }) public fullScreenSupported!: boolean
    @Prop({ default: false }) public autoscale!: boolean

    public combinedPlotData: { layout: any; data: any[] } = { layout: {}, data: [] }
    private hoverMode: string = 'x'

    declare public $refs: {
      plotWrapper: Element
      fullScreenContainer: Element
    }

    public startDateFormatted = new Date(this.startDate)

    public genericGraphStore = new GenericGraphStore()
    public prefsStore = new PrefsStore()

    public plotlyConfig = {
      modeBarButtonsToAdd: ['v1hovermode', 'toggleSpikeLines'],
      modeBarButtonsToRemove: ['autoScale2d', 'lasso2d', 'select2d'],
    }

    @Watch('prefsTimezoneSetting')
    protected onTimezoneSettingChanged() {
      this.loadData()
    }

    @Watch('userUuid')
    private onUserUuidChanged() {
      this.loadData(true)
    }

    @Watch('ring')
    private onRingChanged() {
      this.loadData()
    }

    @Watch('startDate')
    private onStartDateChanged() {
      this.loadData()
    }

    @Watch('endDate')
    private onEndDateChanged() {
      this.loadData()
    }

    @Watch('graphs')
    private onGraphsChanged() {
      this.loadData()
    }

    @Watch('graphMode')
    private onGraphModeChanged() {
      this.loadData()
    }

    @Watch('graphOptions')
    private onGraphOptionsChanged() {
      if (this.graphs.length > 0) {
        this.loadData()
      }
    }

    public mounted() {
      this.genericGraphStore.getGraphOptions({ uuid: this.userUuid })
      if (this.graphs.length > 0) {
        this.loadData()
      }
    }

    protected get plotData() {
      const data = this.genericGraphStore.genericGraphParts
      return data[this.parentComponentName] ? data[this.parentComponentName] : null
    }

    protected get graphMode() {
      return this.prefsStore.graphMode
    }

    protected getPlotDataForGraphs(graphs: string[]) {
      const allPlotData = this.plotData
      const filteredPlotData: any = {}
      for (const graph of graphs) {
        // What if allPlotData[graph] does not exist? It could happen if graph data is deleted.
        // This should be checked and handled gracefully
        filteredPlotData[graph] = allPlotData[graph]
      }
      return filteredPlotData
    }

    protected get graphOptions() {
      return this.genericGraphStore.graphOptions
    }

    public get dataWait() {
      const data = this.genericGraphStore.dataWait
      if (data[this.parentComponentName]) {
        return data[this.parentComponentName] ? data[this.parentComponentName] : false
      } else {
        return false
      }
    }

    /***
     * Returns parent component name.
     * Used to save multiple plots to state when rendering the same plot with different data in multiple
     * components.
     * @private
     */
    private get parentComponentName(): string {
      return Math.random().toString(36).substring(2, 15)
    }

    /***
     * Loads selected generic graphs
     * @private
     */
    @Debounce(500)
    private async loadData(flushBeforeLoad = false) {
      if (!this.graphOptions) {
        return
      }
      // Making copy of selected graphs to prevent mutation while chart is being rendered
      const graphs = [...this.graphs]
      if (flushBeforeLoad || !this.graphs.length) {
        this.combinedPlotData = EMPTY_GRAPH

        for (const graph of graphs) {
          this.genericGraphStore.removeGraphData({
            source: graph,
            callingComponent: this.parentComponentName,
          })
        }
        return
      }

      if (!this.graphOptions) {
        this.genericGraphStore.setDataWait({
          component: this.parentComponentName,
          value: true,
        })
        await this.genericGraphStore.getGraphOptions({ uuid: this.userUuid })
      }
      await this.genericGraphStore.removeUnselectedGenericGraphParts({
        graphs: graphs,
        callingComponent: this.parentComponentName,
      })

      this.startDateFormatted = new Date(this.startDate)
      if (this.startDateFormatted <= new Date(this.endDate)) {
        await this.loadGraphs(graphs)
      }
    }

    private async loadGraphs(graphs: string[]) {
      this.genericGraphStore.setDataWait({
        component: this.parentComponentName,
        value: true,
      })
      const promises: Promise<any>[] = []

      for (const graph of graphs) {
        promises.push(
          this.genericGraphStore.loadGenericGraphParts({
            graph: graph,
            uuid: this.userUuid,
            ringSerial: this.ring?.serialNumber,
            from: this.startDate,
            to: this.endDate,
            timeZone: this.getTimezone(),
            callingComponent: this.parentComponentName,
          }),
        )
      }
      await Promise.all(promises)
        .then(() => {
          if (this.graphOptions && graphs.length > 0) {
            this.combinedPlotData = combinePlotData(
              this.getPlotDataForGraphs(graphs),
              getAxisData(this.graphOptions, graphs),
              this.startDate,
              this.endDate,
              this.graphMode,
            )
          }
          this.combinedPlotData.layout['hovermode'] = this.hoverMode

          this.genericGraphStore.setDataWait({
            component: this.parentComponentName,
            value: false,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  export default toNative(GenericGraph)
</script>

<style lang="scss" scoped></style>
